import React, { lazy } from 'react'
import { App } from '@reactiveonline/frontend_shared_components'
import HeaderIcons from './header/HeaderIcons'
import { additionalElements } from './builder/additionalElements'

// pass 'packageComponent' if the component should be imported inside the package
// pass 'includePageBuilder' if you want to include the additionalElements of the page builder

export default function AppLayout(props) {
  let PageContent = null
  if (!props.packageComponent) {
    PageContent = lazy( () => import(`./${ props.componentPath }`) )
  }

  return (
    <App
      { ...props }
      PageContent={ PageContent }
      appProps={{
        ...props.appProps,
        HeaderIcons: HeaderIcons,
        pageBuilderAdditionalElements: {}
      }}
    />
  )
}
